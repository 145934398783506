:root {
  --color-blue: #12232D;
  --color-red: #E91241;

  --color-highlight: var(--color-red);
  --color-backdrop: var(--color-blue);
}

body {
  background-color: var(--color-backdrop);
  margin: 0;
  padding: 0;
  font-family: 'playtime', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'playtime';
  src: url('./assets/playtime-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

