.root {
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.logo {
  height: 40vmin;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 4vh;
}

.footer {
  color: var(--color-highlight);
  margin: 2em auto;
  text-align: center;
  width: 18em;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  padding: 0;
  list-style: none;
}
